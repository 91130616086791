.item2{
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    
  }
  .item4{
    grid-column-start: 1;
    grid-column-end: 3;
  }
  
  .herowrapper1{
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 4fr 1fr;
    border: 1;
    filter: saturate(1);
    cursor: pointer;
   }

   .herowrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 4fr 1fr;
    -webkit-filter: saturate(1);
    filter: saturate(1);
    cursor: pointer;
    width: 80%;
    align-content: center;
    margin: auto;
    padding: 5px;
    /* box-shadow: 1px 1px 8px 4px black, 1px 1px 8px 4px #de0000, 1px 1px 8px 4px black; */
    box-shadow:1px 1px 8px 4px #7368d4, 1px 1px 8px 4px #483d73, 1px 1px 8px 4px #e4e3ea;
    }
    /* #root > div > div:nth-child(2) > div:nth-child(1) > section {
    margin: 10px;
    } */

  .cardwrapper_nohighlight{
    /* display: grid;
    grid-template-columns: 1fr 5fr;
    grid-template-rows: 2fr 1fr; */
    border: 1;
    filter: saturate(.2);
    cursor: pointer;
   }
   .cardwrapper_highlight{
    /* display: grid;
    grid-template-columns: 1fr 5fr;
    grid-template-rows: 2fr 1fr; */
    border: 1;
    filter: saturate(1.5);
    cursor: pointer;
   }
   .cardwrapper_nohighlight:hover {
      filter: saturate(1.2);
    }
    .cardwrapper_highlight:hover {
      filter: saturate(1.2);
    }
    .comichighlight{
      filter: saturate(2) important;
    }
    .comictitle{
      font-Size: 1.25em;
      font-Weight: bold;
      grid-column: auto / span 2;
    }
    .comicparent{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      grid-column-gap: 8px;
      grid-row-gap: 0px;
      margin:10px;
    }
    .heroparent{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
      grid-column-gap: 8px;
      grid-row-gap: 0px;
      /* margin:10px; */
    }
    .seriesparent{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      grid-column-gap: 8px;
      grid-row-gap: 0px;
      margin:10px;
    }
    .comicdesc{
      display: none;
    }
  