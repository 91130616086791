body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Layout styles -----------------------------------*/
@media (min-width: 620px) {
  main {
    column-count: 2;
    column-gap: 20px;
  }
  
  section {
    break-inside: avoid;
  }
}

@media (min-width: 960px) {
  main {
     column-count: 3;
  }
}

/* styles */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



 .comicwrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}
